import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="text-page">
      <h2>About</h2>
      <p>
        Hi, my name is Rustam, and I'm a web developer and musician. I built this website after reading the book <em>The Advancing Guitarist</em> by jazz guitarist Mick Goodrick. If you want to receive updates on this site or just request new-features, you can follow me on Twitter: <a href="https://twitter.com/rustamrocks?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="false">Follow @rustamrocks</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> 
      </p>
    </div>
  </Layout>
)

export default AboutPage
